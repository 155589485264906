.custom-card {
  height: 100%;
}
.custom-stepper {
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  @media (min-width: 1280px) {
    width: 76%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  /*  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 9999; */
  .stepper {
    &-item {
      width: 25%;
      margin-bottom: 15px;
    }
    &-icon {
      flex: 0 0 auto;
    }
    &-title {
      margin-bottom: 0;
      @media (max-width: 1240px) {
        font-size: rem(16px);
      }
    }
  }
  .invisible {
    display: none !important;
  }
}
.verified-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  margin-left: -9px;
}
.label-color {
  display: none;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive,
td.rdtYear,
td.rdtMonth:hover {
  // background-color: #3F40EF !important;
  border-radius: 26px !important;
}
.rdtPicker td.rdtDay:hover {
  border-radius: 15px !important;
}

.icon_space {
  padding-right: 5px !important;
}

.text-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: 500;
  color: #106e9f;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
//disable the pointer event
.disabled-div {
  pointer-events: none;
}
.hide-step3 {
  display: none !important;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-dropdown {
  max-height: 100px; /* Adjust this value according to your needs */
  overflow-y: auto; /* Add a scrollbar when the content exceeds the max height */
}
