//
// Buttons Theme
//

$mynewcolor: #106e9f;
$gradient: #61c2d0;
$ats-dark-blue: #23536d;

@mixin linear($one, $two) {
  background-image: linear-gradient(90deg, $one -32.48%, $two 122.26%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: white;
  @if (white != null) {
    i {
      color: white;
    }

    .svg-icon {
      @include svg-icon-color(white);
    }

    &.dropdown-toggle:after {
      color: white;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:focus:not(.btn-active),
  &:hover:not(.btn-active),
  &:active:not(.btn-active),
  &.active,
  &.show,
  .show > & {
    @if ($mynewcolor != null) {
      color: white;
    }

    @if ($mynewcolor != null) {
      i {
        color: white;
      }

      .svg-icon {
        @include svg-icon-color(white);
      }

      &.dropdown-toggle:after {
        color: white;
      }
    }

    // @if ( $border-color-active != null ) {
    //     border-color: $border-color-active;
    // }

    // @if ( $mynewcolor != null ) {
    background-image: linear-gradient(to left, #61c2d0, #106e9f);
    z-index: -1;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.1s ease-in-out;
    // }
  }
}


@mixin hover-effect {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    border-radius: 16px;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
  }
}

// Theme colors
@each $name, $value in $theme-colors {
  // Base
  .btn.btn-#{$name} {
    $color: theme-inverse-color($name);
    $icon-color: theme-inverse-color($name);
    $border-color: $value;
    $bg-color: $value;

    $color-active: theme-inverse-color($name);
    $icon-color-active: theme-inverse-color($name);
    $border-color-active: theme-active-color($name);
    $bg-color-active: theme-active-color($name);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
  /*
        @mixin button-variant(
        $background,
        $border,
        $hover-background,
        $hover-border,
        $active-background,
        $active-border
        );
        */

  .btn-outline-ats {
    $color: $mynewcolor;
    $icon-color: $mynewcolor;
    $border-color: $mynewcolor;
    $bg-color: white;

    $color-active: white;
    $icon-color-active: white;
    $border-color-active: theme-active-color($mynewcolor);
    $bg-color-active: $mynewcolor;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  .btn-ats-add {
    @include button-variant($mynewcolor, white, white, $ats-dark-blue, white, white);
  }

  .ats-primary {
    @include linear( $mynewcolor, $gradient);
  }
  // .hover-ats-primary {
  //   @include hover-effect();
  // }

  // Light
  @if (theme-light-color($name)) {
    .btn.btn-light-#{$name} {
      $color: $value;
      $icon-color: $value;
      $border-color: theme-light-color($name);
      $bg-color: theme-light-color($name);

      $color-active: theme-inverse-color($name);
      $icon-color-active: theme-inverse-color($name);
      $border-color-active: $value;
      $bg-color-active: $value;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }

  @if ($btn-extended-variants == true or $name == primary or $name == light) {
    .btn.btn-bg-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: $value;
      $bg-color: $value;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    .btn.btn-active-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: null;
      $bg-color: null;

      $color-active: theme-inverse-color($name);
      $icon-color-active: theme-inverse-color($name);
      $border-color-active: $value;
      $bg-color-active: $value;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    @if (theme-light-color($name)) {
      .btn.btn-active-light-#{$name} {
        $color: null;
        $icon-color: null;
        $border-color: null;
        $bg-color: null;

        $color-active: $value;
        $icon-color-active: $value;
        $border-color-active: theme-light-color($name);
        $bg-color-active: theme-light-color($name);

        @include button-custom-variant(
          $color,
          $icon-color,
          $border-color,
          $bg-color,
          $color-active,
          $icon-color-active,
          $border-color-active,
          $bg-color-active
        );

        // Don't change the border color for outline style
        &.btn-outline:not(.btn-outline-default) {
          border-color: $value !important;
        }
      }
    }
  }
}

// Theme text colors
@each $name, $value in $theme-text-colors {
  @if ($btn-extended-variants == true or $name == primary or $name == muted) {
    // Text and icon colors
    .btn.btn-color-#{$name} {
      $color: $value;
      $icon-color: $value;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text and icon colors active state
    .btn.btn-active-color-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: null;
      $bg-color: null;

      $color-active: $value;
      $icon-color-active: $value;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Icon colors
    .btn.btn-icon-#{$name} {
      $color: null;
      $icon-color: $value;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Icon colors active state
    .btn.btn-active-icon-#{$name} {
      $color: null;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: $value;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text colors
    .btn.btn-text-#{$name} {
      $color: $value;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text colors active state
    .btn.btn-active-text-#{$name} {
      $color: null;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: $value;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }
}

// Social colors
@each $name, $value in $social-colors {
  // Base
  .btn.btn-#{$name} {
    $color: get($value, inverse);
    $icon-color: get($value, inverse);
    $border-color: get($value, base);
    $bg-color: get($value, base);

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: get($value, active);
    $bg-color-active: get($value, active);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Light
  .btn.btn-light-#{$name} {
    $color: get($value, base);
    $icon-color: get($value, base);
    $bg-color: get($value, light);
    $border-color: get($value, light);

    $color-active: get($value, inverse);
    $icon-color-active: get($value, inverse);
    $bg-color-active: get($value, base);
    $border-color-active: get($value, base);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
}

// Outline dashed basic style
.btn.btn-outline.btn-outline-dashed {
  border-width: 1px;
  border-style: dashed;

  $color: null;
  $icon-color: null;
  $border-color: null;
  $bg-color: null;

  $color-active: null;
  $icon-color-active: null;
  $border-color-active: $primary;
  $bg-color-active: null;

  @include button-custom-variant(
    $color,
    $icon-color,
    $border-color,
    $bg-color,
    $color-active,
    $icon-color-active,
    $border-color-active,
    $bg-color-active
  );
}

// Outline dashed default style
.btn.btn-outline.btn-outline-dashed.btn-outline-default {
  border-width: 1px;
  border-style: dashed;

  $color: $light-inverse;
  $icon-color: $light-inverse;
  $border-color: $gray-300;
  $bg-color: null;

  $color-active: $primary;
  $icon-color-active: $primary;
  $border-color-active: $primary;
  $bg-color-active: $primary-light;

  @include button-custom-variant(
    $color,
    $icon-color,
    $border-color,
    $bg-color,
    $color-active,
    $icon-color-active,
    $border-color-active,
    $bg-color-active
  );
}
